import { makeStyles, Theme } from "@material-ui/core";

export const headerAreaInterfloraStyles = makeStyles((theme: Theme) => ({
    container: {
        backgroundColor: "#000000"
    },
    imageContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    nameContainer: {},
    image: {
        width: '100%'
    }
}));