import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const employeeAreaStyles = makeStyles((theme: Theme) => 
    createStyles({
        container: {},
        imageContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
        },
        nameContainer: {
            height: "100%",
        },
        nameInnercontainer: {
            paddingLeft: "15px",
        },
        image: {
            width: '100%',
            height: '100%',
        },
        fileContentBox: {

        },
        phoneText: {
            paddingTop: '10px',
            lineHeight: '1.5em'
        },
        emailText: {
            lineHeight: '1.5em'
        },
        privacyUrlText: {
            paddingTop: '10px',
            lineHeight: '1.5em'
        }
    })
);