export const translations = { 
    da: { 
        translation: {
            rating: {
                title: "Hvad synes du om videoen?",
                dominoTitle: "Du kan skrive dit svar i feltet herunder.",
                commentPlaceholder: "Flere kommentarer",
                dominoCommentPlaceholder: "Dit svar",
                sendButton: "Send vurdering",
                dominoSendButton: "Send vurdering",
                thanks: "Tak, fordi du tog dig tid til at vurdere videoen."
            },
            next: {
                title: "Hvad er næste skridt?",
                dominoTitle: "Du kan også",
                callButton: "Ring op",
                emailButton: "Send e-mail",
                smsButton: "Send SMS",
                watchAgainButton: "Se videoen igen",
            },
            videoNotAvailable: "Videoen er desværre ikke tilgængelig.",
            privacyLink: 'Vores privatlivspolitik'
        }
    },
    se: { 
        translation: {
            rating: {
                title: "Vad tycker du om videon?",
                commentPlaceholder: "Fler kommentarer",
                sendButton: "Skicka recension",
                thanks: "Tack för att du tog dig tid att betygsätta videon."
            },
            next: {
                title: "Vad är nästa steg?",
                callButton: "Ringa upp",
                emailButton: "Skicka epost",
                smsButton: "Skicka SMS",
                watchAgainButton: "Se videon igen",
            },
            videoNotAvailable: "Videon är tyvärr inte tillgänglig.",
            privacyLink: 'Vår integritetspolicy'
        }
    },
    no: { 
        translation: {
            rating: {
                title: "Hva synes du om videoen?",
                commentPlaceholder: "Flere kommentarer",
                sendButton: "Send inn anmeldelse",
                thanks: "Takk for at du tok deg tid til å vurdere videoen."
            },
            next: {
                title: "Hva er det neste trinnet?",
                callButton: "Ringe",
                emailButton: "Send e-post",
                smsButton: "Send tekstmelding",
                watchAgainButton: "Se videoen igjen",
            },
            videoNotAvailable: "Beklager, videoen er ikke tilgjengelig.",
            privacyLink: 'Vår personvernerklæring'
        }
    },
    en: { 
        translation: {
            rating: {
                title: "What do you think of the video?",
                commentPlaceholder: "Further comments",
                sendButton: "Send review",
                thanks: "Thank you, for taking the time to review the video."
            },
            next: {
                title: "What is the next step?",
                callButton: "Call",
                emailButton: "Send email",
                smsButton: "Send SMS",
                watchAgainButton: "Watch again",
            },
            videoNotAvailable: "Sorry, the video is not available.",
            privacyLink: 'Our privacy policy'
        }
    }
}