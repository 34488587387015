import { VideoJsPlayerOptions } from "video.js";

export const options: VideoJsPlayerOptions = {
    fluid: true,
    preload: 'auto',
    nativeControlsForTouch: false,
    html5: {
        hls: {
            enableLowInitialPlaylist: true,
            smoothQualityChange: true,
            overrideNative: true,
            allowFullScreen: false
        },
    },
    controlBar: {
        fullscreenToggle: false
    },
};