import { makeStyles, Theme } from "@material-ui/core";

export const footerAreaInterfloraStyles = makeStyles((theme: Theme) => ({
    container: {
        backgroundColor: "#000000"
    },
    mapsContainer: {},
    maps: {
        width: '400px',
        height: '400px'
    },
    image: {
        width: '100%'
    }
}));