import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";

import {CssBaseline} from "@material-ui/core";
import {Amplify, Analytics, Logger} from "aws-amplify";
import awsExports from "./amplify/aws-exports";
import {initReactI18next} from "react-i18next";
import i18next from "i18next";
import { translations } from './shared/utils/translations';
import PVP from './pvp/PVP';
import PVPInterflora from "./pvp/interflora/PVPInterflora";

const path = window.location.pathname.substring(3, window.location.pathname.length);
const shortCode = path.split('?')[0];

// set background to black if it's jesper nielsen's pvp
if (window.location.href.includes('jespernielsen')) {
    document.body.style.backgroundColor = '#000'
}

if (window.location.href.includes('interflora')) {
    document.body.style.backgroundColor = '#000'
}

Amplify.configure(awsExports);
const logger = new Logger("root");
let splittet: string[] = awsExports["aws_user_files_s3_bucket"].split('-')
if (splittet[splittet.length - 1] !== 'prod') {
    Amplify.Logger.LOG_LEVEL = 'DEBUG';
} else {
    Amplify.Logger.LOG_LEVEL = 'ERROR';
}

logger.debug("Parsed shortCode", shortCode);

Analytics.updateEndpoint({
    address: shortCode, // The unique identifier for the recipient. For example, an address could be a device token, email address, or mobile phone
                        // number.
    attributes: () => {
        return {
            client: () => {
                return 'pvp'
            },
            anonymous: () => {
                return path.indexOf('anonymous') > -1
            }
        }
    }
});

Analytics.autoTrack('session', {
    enable: true,
    attributes: {
        client: 'pvp'
    }
});

/*
Analytics.autoTrack('pageView', {
    enable: true,
    eventName: 'pageView',
    attributes: {
        client: 'pvp'
    },
    type: 'SPA'
});
 */

Analytics.autoTrack('event', {
    enable: true,
    events: ['click'],
    // OPTIONAL, the prefix of the selectors, by default is 'data-amplify-analytics-'
    // in order to avoid collision with the user agent, according to https://www.w3schools.com/tags/att_global_data.asp
    // always put 'data' as the first prefix
    selectorPrefix: 'data-amplify-analytics-',
    attributes: {
        client: 'pvp'
    }
});

i18next
    .use(initReactI18next)
    .init({
        resources: translations,
        fallbackLng: 'en',
        debug: false,
        interpolation: { escapeValue: false },  // React already does escaping
    });

ReactDOM.render(
    <React.StrictMode>
        <CssBaseline/>
        <BrowserRouter>
            <Switch>
                <Route path="/v" render={props => <PVP {...props} />}/>
                <Route path="/interflora" render={props => <PVPInterflora {...props} />}/>
                <Redirect from="/" to="/v"/>
            </Switch>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
