import React, {useContext, useEffect, useRef, useState} from 'react';
import videojs, { VideoJsPlayer } from 'video.js';
import PropTypes from 'prop-types';
import { Box } from "@material-ui/core";
import { Analytics } from "aws-amplify";
import { AmplifyAPI } from "../../api/amplify-api";
import { ViewState, ViewContext } from "../../context/view-context-provider";
import { options } from './VideoPlayer.options';
import './VideoPlayer.styles.css';

const usePlayer = ( props: {
    src: string,
    poster: string,
    videoBgColor: string,
    controls: boolean,
    autoplay: boolean,
    videoShortKey: string,
    videoDone: any,
    anonymous: boolean
}) => {

    const updateEventOnProgressDif: number = 5
    const videoRef = useRef(null);
    const { context, setContext } = useContext(ViewContext)

    const [player, setPlayer] = useState<VideoJsPlayer | null>(null);

    useEffect(() => {
        const vjsPlayer = videojs(videoRef.current, {
            ...options,
            controls: props.controls,
            autoplay: props.autoplay,
            sources: [{ src: props.src }],
            poster: props.poster
        }, async () => {
            setContext({
                ...context,
                videoPlayerReady: true
            })
        });

        // Setting the player will make the effect tracking progress run.
        setPlayer(vjsPlayer);

        if (props.videoShortKey.includes("interflora")) {
            vjsPlayer?.one("ended", () => {
                console.log("Setting video done!")
                props.videoDone(true);
            });
        }

        return () => {
            if (player !== null) {
                player.dispose();
            }
        };
    },
    // eslint-disable-next-line
    []
    );

    useEffect(() => {
        if (context.videoPlayerReady) {
            player?.one("play", async () => {
                const state: ViewState = {
                    ...context,
                    videoStarted: true
                }

                const videoViewId = await AmplifyAPI.addVideoView(
                    state?.videoShortKey as string,
                    state?.videoStarted as boolean,
                    state?.percentageViewed as number,
                    props.anonymous,
                    state?.videoViewId as string
                );

                setContext({
                    ...state as ViewState,
                    videoViewId: videoViewId as string
                })

                Analytics.record({
                    name: 'click',
                    attributes: {
                        button: 'play'
                    }
                });
            });

        }
    // eslint-disable-next-line
    }, [context.videoPlayerReady]);

    useEffect(() => {
        player?.one("ended", async () => {
            const state = {
                ...context,
                percentageViewed: 100
            }

            AmplifyAPI.addVideoView(state?.videoShortKey as string,
                state?.videoStarted as boolean,
                state?.percentageViewed as number,
                props.anonymous,
                state?.videoViewId as string);

            await setContext(state);
            console.log("Setting video done!")
            props.videoDone(true);
        });
    // eslint-disable-next-line
    }, [context.videoViewId]);


    const [durationSecs, setDurationSecs] = useState(0);
    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
        if (context.videoStarted && context.videoViewId !== undefined) {
            //logger.info("UseEffect videoStarted: ", context)
            if (context.percentageViewed < 100) {
                //logger.info("UseEffect percentage under 100: ", context)
                interval = setInterval(async () => {
                    // @ts-ignore - we know the player is set because else the context.videoViewId would not have been set.
                    const currentPct = Math.floor(player?.currentTime() / player?.duration() * 100)
                    // @ts-ignore
                    //logger.info("Current pct: ", player?.currentTime(), player?.duration(), currentPct)

                    // @ts-ignore
                    if (currentPct >= context?.percentageViewed + updateEventOnProgressDif || currentPct === 100) {
                        const state = {
                            ...context,
                            percentageViewed: currentPct
                        };

                        AmplifyAPI.addVideoView(state?.videoShortKey as string,
                            state?.videoStarted as boolean,
                            state?.percentageViewed as number,
                            props.anonymous,
                            state?.videoViewId as string);

                        await setContext(state);
                        if (currentPct === 100) {
                            props.videoDone(true);
                        }
                    }

                    setDurationSecs(remainingSecs => remainingSecs + 1);
                }, 500);
            } else {
                if (interval !== null) {
                    clearInterval(interval as NodeJS.Timeout)
                }
            }
        }

        return () => {
            if (interval !== null) {
                clearInterval(interval as NodeJS.Timeout)
            }
        };
    // eslint-disable-next-line
    }, [context.videoStarted, durationSecs]);

    useEffect(() => {
        if (player !== null) {
            player.src(props.src);
        }
    // eslint-disable-next-line
    }, [props.src]);

    return videoRef;
};

const VideoPlayer = (props: {
    src: string,
    poster: string,
    videoBgColor: string,
    controls: boolean,
    autoplay: boolean,
    videoShortKey: string,
    videoDone: any,
    anonymous: boolean
}) => {

    const playerRef = usePlayer(props);

    return (
        <Box className="c-player">
            <Box className="c-player__screen" data-vjs-player="true">
                <video ref={playerRef}
                    className="video-js vjs-big-play-centered"
                    style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: props.videoBgColor
                    }}
                    playsInline
                />
            </Box>
        </Box>
    )
};

VideoPlayer.propTypes = {
    src: PropTypes.string.isRequired,
    controls: PropTypes.bool,
    autoplay: PropTypes.bool,
};

VideoPlayer.defaultProps = {
    controls: true,
    autoplay: false,
};

export default VideoPlayer;
