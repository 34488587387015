import React, { useContext, useEffect } from "react";
import { Box, Button, Grid, Icon } from "@material-ui/core";
import { documentLinkAreaStyles } from "./DocumentLinkArea.styles";
import { CallToActionType, ViewContext } from "../../context/view-context-provider";

const DocumentLinkArea = (props: {
    externalLink: string
}) => {
    const classes = documentLinkAreaStyles();
    const { onCallToAction } = useContext(ViewContext)
    useEffect(() => {
        // No-op
    })

    return (
        <Grid container
            direction="row"
            justify="center"
            alignItems="stretch"
            className={classes.container}>
            <Grid item xs className={classes.offerContainer}>
                <a onClick={(e) => { onCallToAction(CallToActionType.EXTERNAL_LINK); return true;}}
                    href={props.externalLink}
                    data-amplify-analytics-on='click'
                    data-amplify-analytics-name='click'
                    data-amplify-analytics-attrs='button:external_link'>
                    <img src={"../documentlink-background.jpg"} alt="nykredit banner" className={classes.image}/>
                </a>
            </Grid>
        </Grid>
    )
    
}

export default DocumentLinkArea;