import React from "react";
import {Grid} from "@material-ui/core";
import {footerAreaInterfloraStyles} from "./FooterAreaInterflora.styles";
import {Box, Typography} from "@material-ui/core";

const FooterAreaInterflora = () => {
    const classes = footerAreaInterfloraStyles();

    return (
        <>
            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="stretch"
                  className={classes.container}>
                <Grid item xs className={classes.mapsContainer}>
                    <a href='https://www.interflora.dk'>
                        <img src={"../pvp-footer.png"} alt="interflora logo" className={classes.image}/>
                    </a>
                </Grid>
            </Grid>
        </>

    )
}

export default FooterAreaInterflora;