import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const videoAreaInterfloraStyles = makeStyles((theme: Theme) =>
    createStyles({
        outerWrapper: {
            height: 410,
            display: "flex",
            alignItems: "center",
            flexDirection: 'column'
        },
        wrapper: { 
            height: 120,
            width: '100%',
            alignItems: "center",
            margin: '0 auto'
        },
        container: {
            width: '100%',
            height: '100%',
            color: "#505050",
            margin: '0 auto',
            flexWrap: 'wrap',
            display: 'inline-flex',
            flexFlow: 'row wrap',
            justifyContent: 'center'
        },
        nextButton: {
            border: 1,
            borderStyle: 'solid',
            backgroundColor: 'transparent',
            borderRadius: 3,
            width: 165,
            margin: 7
        },
        ratingWrapper: {
            height: 250,
            width: '100%',
            paddingTop: 10 
        },
        titleContainer: {
            height: 25,
            width: "100%",
            alignItems: 'center',
            marginTop: 20
        },
        titleText: {
            fontWeight: 500,
            textAlign: "center" 
        },
        notAvailableText: {
            fontWeight: 500,
            textAlign: "center",
            paddingBottom: 5
        }
    })
);
