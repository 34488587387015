import React, { useContext, useEffect, useState } from "react";
import { EmployeeAndVideoDetails } from "../../../shared/types/EmployeeAndVideoDetails";
import { S3Object } from "../../../amplify/models";
import { Box, Button, Grid, Icon, Typography } from "@material-ui/core";
import { RatingAndComment } from "../../components/rating-and-comment/RatingAndComment";
import { useTranslation } from "react-i18next";
import { videoAreaStyles } from "./VideoArea.styles";
import { CallToActionType, ViewContext } from "../../context/view-context-provider";
import s3ObjectToUrl from "../../../shared/utils/S3";
import VideoPlayer from "../../components/video-player/VideoPlayer";

const VideoArea = (props: {
    details: EmployeeAndVideoDetails | null
    anonymous: boolean
}) => {
    const style = videoAreaStyles();
    const { t } = useTranslation();
    const { onCallToAction } = useContext(ViewContext)

    const [videoSrc, setVideoSrc] = useState<string | null>(null);
    const [videoThumbnail, setVideoThumbnail] = useState<string | null>(null);
    const [videoDone, setVideoDone] = useState<boolean>(false);
    const [videoDisabled, setVideoDisabled] = useState<boolean>(false);

    useEffect(() => {
        if (props.details !== null) {
            setVideoSrc(s3ObjectToUrl(props.details.video.convertedFile as S3Object))
            setVideoThumbnail(s3ObjectToUrl(props.details.video.thumpnailFile as S3Object))
            setVideoDisabled(props.details.disabled != null ? props.details.disabled : false)
        }
    }, [props.details])

    const onReplayButtonPressed = async (e: any) => {
        await onCallToAction(CallToActionType.REPLAY);
        window.location.reload()
        return false;
    }

    if (videoSrc === null) {
        return null
    } else {

        const user = props.details?.video?.user;
        const shop = user?.shop;

        if (!videoDone && !videoDisabled) {
            return (
                <VideoPlayer 
                    poster={videoThumbnail as string} 
                    videoBgColor={shop?.footerBackgroundColor as string}
                    src={videoSrc as string}
                    videoShortKey={props.details?.videoShortKey as string}
                    videoDone={setVideoDone}
                    anonymous={props.anonymous}
                />
            )
        } else {
            let nextButtonColor = user?.email.includes('@nybolig.dk') ? '#009f6a' : shop?.footerTextColor;
            if (user?.email.includes('@jobboozt.dk')) {
                nextButtonColor = '#F37021'
            }
            if (user?.email.includes('@ejendroem.dk')) {
                nextButtonColor = '#006166'
            }
            const nextTitleKey = user?.email.includes('@guldsmeddomino.dk') ? 'next.dominoTitle' : 'next.title';

            return (
                <Box className={style.outerWrapper} style={{ backgroundColor: shop?.footerBackgroundColor }}>
                    <Box className={style.ratingWrapper}>
                        <RatingAndComment footerTextColor={shop?.footerTextColor ? shop?.footerTextColor : '#000'} footerBackgroundColor={shop?.footerBackgroundColor ? shop?.footerBackgroundColor  : '#FFF' } email={user?.email as string} />
                    </Box>
                    <Box className={style.titleContainer} style={{backgroundColor: shop?.footerBackgroundColor}}>
                    {
                        videoDisabled ? 
                            <Typography className={style.notAvailableText} style={{color: shop?.footerTextColor}}>{t('videoNotAvailable')}</Typography>
                        :
                            !user?.email?.includes('@nykredit.dk') ? <Typography className={style.titleText} style={{color: shop?.footerTextColor}}>{t(nextTitleKey)}</Typography> : null }
                    </Box>
                    { !user?.email?.includes('@nykredit.dk') ?
                    <Box className={style.wrapper}>
                        <Grid container direction="row" justify="center" alignContent="center" className={style.container}>
                            <Button 
                                className={style.nextButton}
                                style={{color: nextButtonColor, borderColor: nextButtonColor}}
                                onClick={(e) => { onCallToAction(CallToActionType.CALL); return true;}}
                                href={'tel:' + user?.number}
                                data-amplify-analytics-on='click'
                                data-amplify-analytics-name='click'
                                data-amplify-analytics-attrs='button:cta_call'
                                startIcon={<Icon>phone</Icon>}>
                                {t('next.callButton')}
                            </Button>
                            <Button 
                                className={style.nextButton}
                                style={{color: nextButtonColor, borderColor: nextButtonColor}}
                                onClick={(e) => { onCallToAction(CallToActionType.EMAIL); return true;}}
                                href={'mailto:' + user?.email}
                                data-amplify-analytics-on='click'
                                data-amplify-analytics-name='click'
                                data-amplify-analytics-attrs='button:cta_email'
                                startIcon={<Icon>send</Icon>}>
                                {t('next.emailButton')}
                            </Button>
                            { !user?.email.includes('jen@lho.dk') ?
                                    <Button
                                        className={style.nextButton}
                                        style={{color: nextButtonColor, borderColor: nextButtonColor}}
                                        onClick={(e) => { onCallToAction(CallToActionType.SMS); return true;}}
                                        href={('sms://' + (user?.email.includes("butik@guldsmeddomino.dk") ? '+4522914546' : user?.number))}
                                        data-amplify-analytics-on='click'
                                        data-amplify-analytics-name='click'
                                        data-amplify-analytics-attrs='button:cta_sms'
                                        startIcon={<Icon>sms</Icon>}>
                                        {t('next.smsButton')}
                                    </Button>
                                : null }
                            {
                                !videoDisabled ?
                                    <Button 
                                        className={style.nextButton}
                                        style={{color: nextButtonColor, borderColor: nextButtonColor}} 
                                        onClick={onReplayButtonPressed}
                                        data-amplify-analytics-on='click'
                                        data-amplify-analytics-name='click'
                                        data-amplify-analytics-attrs='button:watch_again'
                                        startIcon={<Icon>replay</Icon>}>
                                        {t('next.watchAgainButton')}
                                    </Button> 
                                : null
                            }
                        </Grid>
                    </Box> : null }
                </Box>
            )
        }
    }
}

export default VideoArea;
