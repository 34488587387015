import React, {useEffect, useState} from "react";
import {Box, Button, Grid, Icon, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {videoAreaInterfloraStyles} from "./VideoAreaInterflora.styles";
import VideoPlayer from "../components/video-player/VideoPlayer";

const VideoAreaInterflora = () => {
    const style = videoAreaInterfloraStyles();
    const {t} = useTranslation();

    const [videoSrc, setVideoSrc] = useState<string | null>("https://d3gj4b4fh2ovze.cloudfront.net/public/7d5b332d-03d0-4698-a0ea-64bbe0addaa5/7d5b332d-03d0-4698-a0ea-64bbe0addaa5.m3u8");
    const [videoThumbnail, setVideoThumbnail] = useState<string | null>("https://d3gj4b4fh2ovze.cloudfront.net/public/thumpnails/7d5b332d-03d0-4698-a0ea-64bbe0addaa5.0000000.jpg");
    const [videoDone, setVideoDone] = useState<boolean>(false);
    const [videoDisabled, setVideoDisabled] = useState<boolean>(false);

    useEffect(() => {
        console.log("Use effect!");
        //no-op
    }, [videoDone])

    const onReplayButtonPressed = async (e: any) => {
        window.location.reload()
        return false;
    }

    if (!videoDone && !videoDisabled) {
        return (
            <VideoPlayer
                poster={videoThumbnail as string}
                videoBgColor="#000000"
                src={videoSrc as string}
                videoShortKey={"interflora"}
                videoDone={setVideoDone}
                anonymous={false}
            />
        )
    } else {
        const nextButtonColor = '#d0b04e';

        return (
            <Box className={style.outerWrapper} style={{backgroundColor: "#000000"}}>
                <Box className={style.titleContainer} style={{backgroundColor: "#000000"}}>
                    <Typography className={style.titleText}
                                style={{color: "#FFFFFF", fontSize: 24}}>💐 Vil du vende tilbage?</Typography>
                </Box>
                <Box className={style.wrapper}>
                    <Grid container direction="row" justify="center" alignContent="center" className={style.container}>
                        <Button
                            className={style.nextButton}
                            style={{color: nextButtonColor, borderColor: nextButtonColor}}
                            href={'tel:+4512345678'}
                            data-amplify-analytics-on='click'
                            data-amplify-analytics-name='click'
                            data-amplify-analytics-attrs='button:cta_call'
                            startIcon={<Icon>phone</Icon>}>
                            RING
                        </Button>
                        <Button
                            className={style.nextButton}
                            style={{color: nextButtonColor, borderColor: nextButtonColor}}
                            href={'sms://+4512345678'}
                            data-amplify-analytics-on='click'
                            data-amplify-analytics-name='click'
                            data-amplify-analytics-attrs='button:cta_sms'
                            startIcon={<Icon>sms</Icon>}>
                            SEND SMS
                        </Button>
                        <Button
                            className={style.nextButton}
                            style={{color: nextButtonColor, borderColor: nextButtonColor}}
                            onClick={onReplayButtonPressed}
                            data-amplify-analytics-on='click'
                            data-amplify-analytics-name='click'
                            data-amplify-analytics-attrs='button:watch_again'
                            startIcon={<Icon>replay</Icon>}>
                            SE IGEN
                        </Button>
                    </Grid>
                </Box>
            </Box>
        )
    }
}

export default VideoAreaInterflora;
