import { makeStyles, Theme } from "@material-ui/core";

export const headerAreaStyles = makeStyles((theme: Theme) => ({
    container: {},
    imageContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    nameContainer: {},
    image: {
        width: theme.spacing(10),
        objectFit: 'cover',
        height: '100%',
    }
}));