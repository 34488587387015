import React, { useContext, useEffect, useState } from "react";
import { EmployeeAndVideoDetails } from "../../../shared/types/EmployeeAndVideoDetails";
import { Avatar, Box, Grid, Typography } from "@material-ui/core";
import { useTranslation} from "react-i18next";
import { CallToActionType, ViewContext } from "../../context/view-context-provider";
import { employeeAreaStyles } from "./EmployeeArea.styles";
import { EmployeeView } from "../../../shared/types/EmployeeView";
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js'
import s3ObjectToUrl from "../../../shared/utils/S3";

const EmployeeArea = (props: {
    employeeAndVideo: EmployeeAndVideoDetails | null
}) => {
    const { t } = useTranslation();
    const classes = employeeAreaStyles();
    const { onCallToAction } = useContext(ViewContext)

    const [employee, setEmployeeView] = useState<EmployeeView | null>(null);

    useEffect(() => {
        if (props.employeeAndVideo != null) {
            const user = props.employeeAndVideo.video.user;
            const shop = user.shop;

            setEmployeeView({
                name: user.firstName + " " + user.lastName,
                locale: user.locale,
                title: user.jobTitle,
                phone: user.number,
                email: user.email,
                avatarUrl: s3ObjectToUrl(user.avatar),
                textColor: user.shop.footerTextColor,
                backgroundColor: user.shop.footerBackgroundColor,
                privacyPolicyUrl: shop.privacyPolicyUrl
            })
        }
    }, [props.employeeAndVideo])

    return (
        <Grid container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.container}
            style={{ backgroundColor: employee?.backgroundColor, color: employee?.textColor }}>

            <Grid item xs={employee?.name.includes('BOLOU') ? 5 : 4} className={classes.imageContainer}>
                <Avatar alt={employee?.name} src={employee?.avatarUrl} variant="square" className={classes.image} />
            </Grid>

            <Grid item xs={employee?.name.includes('BOLOU') ? 5 : 8} className={classes.nameContainer}>
                <Box p={1}>
                    <Grid container
                        direction="column"
                        justify="flex-start"
                        alignItems="stretch"
                        className={classes.nameInnercontainer}>

                        <Grid item xs>
                            <Typography variant="subtitle2" component="h2" style={{textTransform: employee?.name.includes('BOLOU') ? "uppercase" : "none"}}>{ employee?.name}</Typography>
                            <Typography variant="caption" display="block" style={{textTransform: employee?.name.includes('BOLOU') ? "uppercase" : "none"}}>{ employee?.title }</Typography>
                        </Grid>
                        <Grid item xs>

                            <Typography className={classes.phoneText} variant="overline">
                                <a href={ 'tel:' + employee?.phone }
                                    style={{ color: employee?.textColor, textDecorationLine: 'none', textTransform: employee?.name.includes('BOLOU') ? "uppercase" : "none"}}
                                    onClick={(e) => { 
                                        onCallToAction(CallToActionType.CALL);
                                        return true;
                                    }}
                                    data-amplify-analytics-on='click'
                                    data-amplify-analytics-name='click'
                                    data-amplify-analytics-attrs='button:number'
                                >
                                    { 
                                        employee?.phone ? 
                                        parsePhoneNumber(
                                            employee?.phone as string,
                                            employee?.locale.substring(3, employee?.locale.length) as CountryCode
                                        )?.formatInternational() :
                                        employee?.phone
                                    }
                                </a>
                            </Typography>

                            <Typography className={classes.emailText} variant="caption" display="block">
                                <a href={'mailto:' + employee?.email} 
                                    style={{ color: employee?.textColor, textDecorationLine: 'none', textTransform: employee?.name.includes('BOLOU') ? "uppercase" : "none"}}
                                    onClick={(e) => {
                                        onCallToAction(CallToActionType.EMAIL);
                                        return true;
                                    }}
                                    data-amplify-analytics-on='click'
                                    data-amplify-analytics-name='click'
                                    data-amplify-analytics-attrs='button:email'
                                >
                                    { employee?.email }
                                </a>
                            </Typography>

                        </Grid>

                        { 
                            employee?.privacyPolicyUrl ?
                                <Grid item xs>
                                    <Typography className={classes.privacyUrlText} variant="caption" display="block">
                                        <a href={ employee?.privacyPolicyUrl }
                                            target={"_blank"}
                                            rel="noopener noreferrer"
                                            style={{ textDecorationLine: 'none', color: '#444'}}
                                            onClick={(e) => {
                                                onCallToAction(CallToActionType.PRIVACY_POLICY_LINK);
                                                return true;
                                            }}
                                            data-amplify-analytics-on='click'
                                            data-amplify-analytics-name='click'
                                            data-amplify-analytics-attrs='button:privacyPolicy'
                                        >
                                            » { t('privacyLink') }
                                        </a>
                                    </Typography>
                                </Grid>
                            : null
                        }
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}

export default EmployeeArea;
