import React from "react";
import {Avatar, Box, Grid, Typography} from "@material-ui/core";
import {headerAreaInterfloraStyles} from "./HeaderAreaInterflora.styles";

const HeaderAreaInterflora = () => {
    const classes = headerAreaInterfloraStyles();

    return (
        <Grid container
              direction="row"
              justify="center"
              alignItems="stretch"
              className={classes.container}>
            <Grid item xs style={{display: "contents"}}>
                <img src={"../pvp-header.png"} alt="interflora logo" className={classes.image}/>
            </Grid>
        </Grid>
    )
}
export default HeaderAreaInterflora;