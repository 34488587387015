import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const ratingAndCommentStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            '& > *': {
                margin: '0 auto',
                display: 'flex',
                flexDirection: 'column',
                width: '35ch'
            }
        },
        thanks: {
            width: '70%',
            height: '50%',
            margin: '0 auto',
            textAlign: 'center',
            paddingTop: 70
        },
        thanksText: {
            fontSize: 18,
            fontWeight: 600
        },
        ratingTitle: {
            fontWeight: 500,
            textAlign: "center",
            marginTop: 15,
            paddingBottom: 10
        },
        ratingBox: {
            alignItems: 'center',
            paddingBottom: 15
        },
        commentInput: {
            borderRadius: 5,
            padding: 10,
            fontFamily: 'Verdana'
        },
        ratingButton: {
            color: '#FFF',
            border: 1,
            borderStyle: 'solid',
            backgroundColor: '#009f6a',
            borderColor: 'transparent',
            borderRadius: 3
        }
    }),
);
