import React, {useEffect, useState} from "react";
import { Container, Grid } from "@material-ui/core";
import { Logger} from "aws-amplify";
import { AmplifyAPI} from "./api/amplify-api";
import { EmployeeAndVideoDetails} from "../shared/types/EmployeeAndVideoDetails";
import { S3Object } from "../amplify/models";
import { ViewContextProvider } from "./context/view-context-provider";
import { useTranslation } from "react-i18next";
import { pvpStyles } from "./pvp.styles";
import VideoArea from "./sections/video-area/VideoArea";
import CircularProgress from "@material-ui/core/CircularProgress";
import s3ObjectToUrl from "../shared/utils/S3";
import EmployeeArea from "./sections/employee-area/EmployeeArea";
import FooterArea from "./sections/footer-area/FooterArea";
import HeaderArea from "./sections/header-area/HeaderArea";
import DocumentLinkArea from "./sections/document-link-area/DocumentLinkArea";

const logger = new Logger("PVP")

const PVP = (props: any) => {
    const style = pvpStyles();
    const { i18n } = useTranslation()
    const anonymous = props.location.search.includes('anonymous');
    const videoKey = props.location.pathname.split('/')[2];

    const [employeeAndVideoDetails, setEmployeeAndVideoDetails] = useState<EmployeeAndVideoDetails | null>(null)

    useEffect(() => {
        if (videoKey && videoKey.length === 8) {
            AmplifyAPI.getEmployeeAndVideoDetails(videoKey).then(async (details: EmployeeAndVideoDetails) => {
                let videoReady: boolean = false;
                let counter = 0;
                while (!videoReady && counter < 30) {
                    try {
                        const response = await fetch(s3ObjectToUrl(details.video.convertedFile as S3Object))
                        if (response.status === 200) {
                            videoReady = true
                        } else {
                            await new Promise(resolve => setTimeout(resolve, 1000))
                        }
                    } catch (err) {
                        counter++
                    }
                }

                // change language from user locale
                i18n.changeLanguage(details.video.user.locale.substr(0, 2));

                setEmployeeAndVideoDetails(details)
                logger.debug("Mapped details", details)
            })
        } else {
            logger.error("Corrupt videokey", props.location.pathname)
        }
    // eslint-disable-next-line
    }, [props.location])

    return (
        <Container maxWidth="sm" disableGutters={true}>
            {
                employeeAndVideoDetails ?
                    <Grid container justify="flex-start" alignItems="stretch" className={style.root}>
                        <ViewContextProvider videoShortKey={videoKey} anonymous={anonymous}>
                            <Grid item xs={12} className={style.header}>
                                <HeaderArea employeeAndVideo={employeeAndVideoDetails}/>
                            </Grid>
                            <Grid item xs={12} className={style.body}>
                                <VideoArea details={employeeAndVideoDetails} anonymous={anonymous}/>
                            </Grid>
                            {
                                employeeAndVideoDetails.externalLink ?
                                (() => {
                                    return (
                                        <Grid item xs={12} className={style.footerEmployee}>
                                            <DocumentLinkArea externalLink={employeeAndVideoDetails.externalLink}/>
                                        </Grid>
                                    )
                                })() : 
                                null
                            }
                            <Grid item xs={12} className={style.footerEmployee}>
                                <EmployeeArea employeeAndVideo={employeeAndVideoDetails}/>
                            </Grid>
                            <Grid item xs={12} className={style.footerMaps}>
                                <FooterArea employeeAndVideo={employeeAndVideoDetails}/>
                            </Grid>
                        </ViewContextProvider>
                    </Grid>
                :
                    <CircularProgress 
                        className={style.circularProgress}
                        thickness={6} 
                        size='4rem' 
                    />
            }
        </Container>
    )
}

export default PVP;