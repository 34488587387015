import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const pvpStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
            display: 'flex',
        },
        header: {},
        body: {},
        footerEmployee: {},
        footerMaps: {},
        progressContainer: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(0.5),
            },
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            height: "100%"
        },
        progressGridItem: {
            justifyContent: ""
        },
        circularProgress: {
            position: "absolute",
            left: "41%",
            top: "44%",
            color: '#00a57d'
        }
    })
);
