import { makeStyles, Theme } from "@material-ui/core";

export const documentLinkAreaStyles = makeStyles((theme: Theme) => ({
    container: {},
    offerContainer: {
        display: 'flex'
    },
    image: {
        width: '100%'
    },
}));