import React, { useContext, useEffect, useState } from "react";
import { Box, TextareaAutosize, Typography, Button } from "@material-ui/core";
import { Rating } from '@material-ui/lab';
import { AmplifyAPI } from "../../api/amplify-api";
import { ViewContext } from "../../context/view-context-provider";
import { useTranslation } from "react-i18next";
import { ratingAndCommentStyles } from "./RatingAndComment.styles";

/* Se mere om styling og muligheder for rating komponent her https://material-ui.com/components/rating/ */
export const RatingAndComment: React.FunctionComponent<{footerTextColor: string, footerBackgroundColor: string, email: string}> = (props) => {
    const style = ratingAndCommentStyles();
    const { t } = useTranslation();
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { context, setContext } = useContext(ViewContext)

    const [rating, setRating] = useState<number | null>(null);
    const [furtherComment, setFurtherComment] = useState<string>("");
    const [submitted, setSubmitted] = useState<boolean>(false);

    useEffect(() => {
        // No-op
    }, [context])

    const submit = async (event: any) => {
        event.preventDefault();
        await AmplifyAPI.addOrUpdateRatingAndComment(
            context.videoViewId as string,
            context.videoShortKey as string,
            context.anonymous,
            rating,
            furtherComment
        );
        setSubmitted(true);
    }

    const ratingTitleKey = props.email.includes('@guldsmeddomino.dk') ? 'rating.dominoTitle' : 'rating.title';
    const commentPlaceholderKey = props.email.includes('@guldsmeddomino.dk') ? 'rating.dominoCommentPlaceholder' : 'rating.title';
    const sendButtonKey = props.email.includes('@guldsmeddomino.dk') ? 'rating.dominoSendButton' : 'rating.sendButton';
    const isPoulStig = props.email.includes('@poulstigbriller.dk');


    let buttonColor = isPoulStig ? '#322881' : props.footerTextColor;
    if (props.footerBackgroundColor === '#000000') {
        buttonColor = '#009f6a'
    }
    if (props?.email.includes('@jobboozt.dk')) {
        buttonColor = '#F37021'
    }

    return (
        <Box>
            { 
                submitted ?
                    <Box className={style.thanks}>
                        <Typography className={style.thanksText}>{t('rating.thanks')}</Typography>
                    </Box>
                :
                    <form className={style.form} autoComplete="off" onSubmit={submit}>
                        <Box>
                            <Typography className={style.ratingTitle} style={{color: props.footerTextColor}}>{t(ratingTitleKey)}</Typography>
                        </Box>
                        <Box className={style.ratingBox}>
                            <Rating 
                                size="large"
                                name="simple-controlled"
                                value={rating}
                                onChange={(event, newValue) => { 
                                    setRating(newValue)}
                                }
                            />
                        </Box>
                        <Box>
                            <TextareaAutosize
                                className={style.commentInput}
                                aria-label="minimum height"
                                rowsMin={3}
                                placeholder={t(commentPlaceholderKey)}
                                value={furtherComment}
                                onChange={(event) => {
                                    setFurtherComment(event.target.value);
                                }}
                            />
                        </Box>
                        <Box style={{marginTop: 10}}>
                            <Button type="submit" value="Submit" className={style.ratingButton} style={{backgroundColor: buttonColor}}>
                                { t(sendButtonKey) }
                            </Button>
                        </Box>
                    </form>
            }
        </Box>
    )
}