import React from "react";
import {Container, Grid} from "@material-ui/core";
import {pvpInterfloraStyles} from "./pvp-interflora.styles";
import HeaderAreaInterflora from "./HeaderAreaInterflora";
import VideoAreaInterflora from "./VideoAreaInterflora";
import FooterAreaInterflora from "./FooterAreaInterflora";

const PVPInterflora = (props: any) => {
    const style = pvpInterfloraStyles();

    return (
        <Container maxWidth="sm" disableGutters={true}>
            <Grid container justify="flex-start" alignItems="stretch" className={style.root}>
                <Grid item xs={12} className={style.header}>
                    <HeaderAreaInterflora/>
                </Grid>
                <Grid item xs={12} className={style.body}>
                    <VideoAreaInterflora/>
                </Grid>
                <Grid item xs={12} className={style.footerMaps}>
                    <FooterAreaInterflora/>
                </Grid>
            </Grid>
        </Container>
    )
}

export default PVPInterflora;