import { Auth, Logger } from "aws-amplify";

import { EmployeeAndVideoDetails } from "../../shared/types/EmployeeAndVideoDetails";
import { LamdaResponse } from "../../shared/types/LamdaResponse";
import { CallToAction } from "../context/view-context-provider";
import Lambda from "aws-sdk/clients/lambda";
import awsExports from "../../amplify/aws-exports";

const logger = new Logger("AmplifyAPI")

export class AmplifyAPI {

    /**
     * Calls the lambda function diialoggGetPVPEmployee - which will
     * @param videoShortKey
     */
    public static getEmployeeAndVideoDetails(videoShortKey: string): Promise<EmployeeAndVideoDetails> {
        return new Promise<EmployeeAndVideoDetails>((resolve, reject) => {
            Auth.currentCredentials()
                .then(credentials => {
                    const lambda = new Lambda({
                        credentials: Auth.essentialCredentials(credentials),
                        region: awsExports["aws_project_region"]
                    });

                    var params = {
                        FunctionName: "diialoggGetPVPEmployee-" + this.getEnv(),
                        // Qualifier: 'provisioned',
                        Payload: JSON.stringify({
                            videoShortKey: videoShortKey
                        })
                    };

                    return lambda.invoke(params, function (err, data) {
                        if (err) {
                            //TODO: How do we handle errors?

                            logger.error("Error invoking lambda getEmployeeAndVideoDetails", err, err.stack); // an error occurred
                            reject(err)
                        } else {
                            logger.debug("Success calling lambda getEmployeeAndVideoDetails . Result:", data);           // successful response
                            let response: LamdaResponse<EmployeeAndVideoDetails> = JSON.parse(data.Payload as string);
                            if (response.statusCode === 200) {
                                const details = response.body as EmployeeAndVideoDetails
                                if (!details.video.user.shop.deactivated) {
                                    if (!details.video.user.locale) {
                                        details.video.user.locale = 'da-DK';
                                    }
                                    
                                    resolve(details)
                                } else {
                                    logger.error("Shop is deactivated",);
                                }
                            } else {
                                logger.error("Lambda didnt return expected response getEmployeeAndVideoDetails",); // an error occurred
                                //TODO: Handle 409 statusCode
                            }

                        }
                    });
                })
        })
    }

    /**
     * Calls the lambda function PVPViewAndRatingHandler - which will
     * @param videoShortKey
     * @param videoStarted
     * @param percentageViewed
     * @param anonymous
     * @param videoViewId
     * @return the id of the created video view
     */
    public static addVideoView(videoShortKey: string, videoStarted: boolean, percentageViewed: number, anonymous: boolean, videoViewId?: string,): Promise<string> {
        if (anonymous !== null && anonymous) {
            return new Promise<string>((resolve, reject) => {
                resolve("")
            });
        }

        return new Promise<string>((resolve, reject) => {
            Auth.currentCredentials()
                .then(credentials => {
                    const lambda = new Lambda({
                        credentials: Auth.essentialCredentials(credentials),
                        region: awsExports["aws_project_region"]
                    });

                    var params = {
                        FunctionName: "PVPViewAndRatingHandler-" + this.getEnv(),
                        // Qualifier: 'provisioned',
                        Payload: JSON.stringify({
                            videoShortKey: videoShortKey,
                            videoViewId: videoViewId,
                            videoStarted: videoStarted,
                            percentageViewed: percentageViewed
                        })
                    };

                    return lambda.invoke(params, function (err, data) {
                        if (err) {
                            //TODO: How do we handle errors?
                            logger.error("Error invoking lambda addVideoView", err, err.stack); // an error occurred
                            reject(err)
                        } else {
                            logger.debug("Success calling lambda addVideoView. Result:", data); // successful response
                            let response = JSON.parse(data.Payload as string);
                            if (response.statusCode === 200) {
                                resolve(response.videoViewId)
                            } else {
                                logger.error("Lambda didnt return expected response addVideoView",); // an error occurred
                                //TODO: Handle 409 statusCode
                            }

                        }
                    });
                })
        })
    }

    /**
     * Calls the lambda function diialoggGetPVPEmployee - which will
     * @param viewId
     * @param videoShortKey
     * @param anonymous
     * @param rating
     * @param comment
     * @return the id of the created video view
     */
    public static addOrUpdateRatingAndComment(viewId: string, videoShortKey: string, anonymous: boolean, rating: number | null, comment: string): Promise<string> {
        if (anonymous !== null && anonymous) {
            return new Promise<string>((resolve, reject) => {
                resolve("")
            });
        }

        return new Promise<string>((resolve, reject) => {
            Auth.currentCredentials()
                .then(credentials => {
                    const lambda = new Lambda({
                        credentials: Auth.essentialCredentials(credentials),
                        region: awsExports["aws_project_region"]
                    });

                    var params = {
                        FunctionName: "PVPViewAndRatingHandler-" + this.getEnv(),
                        // Qualifier: 'provisioned',
                        Payload: JSON.stringify({
                            videoShortKey: videoShortKey,
                            videoViewId: viewId,
                            rating: rating,
                            comment: comment !== "" ? comment : undefined
                        })
                    };

                    return lambda.invoke(params, function (err, data) {
                        if (err) {
                            //TODO: How do we handle errors?
                            logger.error("Error invoking lambda addOrUpdateRatingAndComment", err, err.stack); // an error occurred
                            reject(err)
                        } else {
                            logger.debug("Success calling lambda addOrUpdateRatingAndComment. Result:", data);           // successful response
                            let response = JSON.parse(data.Payload as string);
                            if (response.statusCode === 200) {
                                resolve(response.videoViewId)
                            } else {
                                logger.error("Lambda didnt return expected response addOrUpdateRatingAndComment",); // an error occurred
                                //TODO: Handle 409 statusCode
                            }
                        }
                    });
                })
        })
    }

    /**
     * Calls the lambda function diialoggGetPVPEmployee - which will
     * @param viewId
     * @param videoShortKey
     * @param anonymous
     * @param ctas
     * @return the id of the created video view
     */
    public static addOrUpdateCTAS(viewId: string, videoShortKey: string, anonymous: boolean, ctas: CallToAction[]): Promise<string> {
        if (anonymous !== null && anonymous) {
            return new Promise<string>((resolve, reject) => {
                resolve("")
            });
        }

        return new Promise<string>((resolve, reject) => {
            Auth.currentCredentials()
                .then(credentials => {
                    const lambda = new Lambda({
                        credentials: Auth.essentialCredentials(credentials),
                        region: awsExports["aws_project_region"]
                    });

                    var params = {
                        FunctionName: "PVPViewAndRatingHandler-" + this.getEnv(),
                        // Qualifier: 'provisioned',
                        Payload: JSON.stringify({
                            videoShortKey: videoShortKey,
                            videoViewId: viewId,
                            ctas: ctas
                        })
                    };

                    return lambda.invoke(params, function (err, data) {
                        if (err) {
                            //TODO: How do we handle errors?
                            logger.error("Error invoking lambda addOrUpdateCTAS", err, err.stack); // an error occurred
                            reject(err)
                        } else {
                            logger.debug("Success calling lambda addOrUpdateCTAS. Result:", data);           // successful response
                            let response = JSON.parse(data.Payload as string);
                            if (response.statusCode === 200) {
                                resolve(response.videoViewId)
                            } else {
                                logger.error("Lambda didnt return expected response addOrUpdateCTAS",); // an error occurred
                                //TODO: Handle 409 statusCode
                            }
                        }
                    });
                })
        })
    }

    /**
     * Messy way of guessing the environment!
     */
    private static getEnv(): string {
        let splittet: string[] = awsExports["aws_user_files_s3_bucket"].split('-')
        return splittet[splittet.length - 1]
    }

}
