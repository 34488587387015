import {S3Object} from "../../amplify/models";


const s3ObjectToUrl = (object: S3Object) => {
    if (object.bucket.includes('cloudfront')) {
        return "https://" + object.bucket + "/" + object.key
    } else {
        return "https://" + object.bucket + ".s3." + object.region + ".amazonaws.com/" + object.key
    }
};

export default s3ObjectToUrl;