import React, {useContext, useEffect, useState} from "react";
import {AmplifyAPI} from "../api/amplify-api";


export interface ViewState {
    videoShortKey: string | undefined,
    anonymous: boolean,
    videoViewId: string | undefined,
    videoPlayerReady: boolean,
    videoStarted: boolean,
    percentageViewed: number,
    ctas: CallToAction[]
}

export enum CallToActionType {
    REPLAY = "REPLAY",
    CALL = "CALL",
    SMS = "SMS",
    EMAIL = "EMAIL",
    EXTERNAL_LINK = "EXTERNAL_LINK",
    PRIVACY_POLICY_LINK = "PRIVACY_POLICY_LINK"
}

export interface CallToAction {
    count: number
    type: CallToActionType
}

export const ViewContext = React.createContext<{ context: ViewState, setContext: React.Dispatch<React.SetStateAction<ViewState>>, onCallToAction: (type: CallToActionType) => Promise<void> }>({
    context: {
        videoShortKey: undefined,
        anonymous: false,
        videoViewId: undefined,
        videoPlayerReady: false,
        videoStarted: false,
        percentageViewed: 0,
        ctas: []
    },
    setContext(value: ((prevState: ViewState) => ViewState) | ViewState): void {},
    onCallToAction(type: CallToActionType): Promise<void> {
        return Promise.resolve(undefined);
    }
});

export const ViewContextProvider: React.FunctionComponent<{ videoShortKey: string, anonymous: boolean }> = (props) => {
    const [viewContext, setViewContext] = useState<ViewState>(useContext(ViewContext)?.context as ViewState);

    useEffect(() => {
        setViewContext({
            ...viewContext,
            videoShortKey: props.videoShortKey,
            anonymous: props.anonymous
        })
        // eslint-disable-next-line
    }, [props])


    const onCallToAction = async (type: CallToActionType) => {
        var index = viewContext.ctas.findIndex((cta) => cta.type === type)
        if (index === -1) {
            viewContext.ctas.push({type: type, count: 1})
            await setViewContext(viewContext)
        } else {
            viewContext.ctas[index].count = viewContext.ctas[index].count + 1
            await setViewContext(viewContext)
        }

        await AmplifyAPI.addOrUpdateCTAS(viewContext.videoViewId as string, viewContext.videoShortKey as string, viewContext.anonymous, viewContext.ctas).then((viewId) => {
            if (!viewContext.videoViewId) {
                setViewContext({
                    ...viewContext,
                    videoViewId: viewId
                })
            }
        });
    }

    return (
        <ViewContext.Provider
            value={{context: viewContext, setContext: setViewContext, onCallToAction: onCallToAction}}>
            {props.children}
        </ViewContext.Provider>
    )
}