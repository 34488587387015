import { makeStyles, Theme } from "@material-ui/core";

export const footerAreaStyles = makeStyles((theme: Theme) => ({
    container: {},
    mapsContainer: {},
    maps: {
        width: '400px',
        height: '400px'
    },
    image: {
        width: '100%'
    }
}));