import React, { useEffect, useState } from "react";
import { EmployeeAndVideoDetails } from "../../../shared/types/EmployeeAndVideoDetails";
import { Avatar, Box, Grid, Typography } from "@material-ui/core";
import { headerAreaStyles } from "./HeaderArea.styles";
import { ShopView } from "../../../shared/types/ShopView";
import s3ObjectToUrl from "../../../shared/utils/S3";

const HeaderArea = (props: {
    employeeAndVideo: EmployeeAndVideoDetails | null
}) => {
    const classes = headerAreaStyles();

    const [shop, setShopView] = useState<ShopView | null>(null);
    

    useEffect(() => {
        if (props.employeeAndVideo != null) {
            const shop = props.employeeAndVideo.video.user.shop;

            var tmpSubtitleTextColor = shop.headerTextColor
            if (shop.name.toLowerCase().includes("service & co")) {
                tmpSubtitleTextColor = '#000000'
            } else if (shop.name.toLowerCase().includes("guldsmed")) {
                tmpSubtitleTextColor = '#FFFFFF'
            }

            setShopView({
                name: shop.name,
                subname: shop.subname,
                logoUrl: s3ObjectToUrl(shop.logo),
                headerbackground: shop.headerBackgroundColor,
                textColor: shop.headerTextColor,
                subtitleTextColor: tmpSubtitleTextColor
            })
        }
    }, [props.employeeAndVideo])

    return (
        <Grid container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.container} 
            style={{ 
                backgroundColor: shop?.headerbackground,
                color: shop?.textColor
            }}
        >   
            { 
                (() => {
                    if (shop?.name.includes("able")) {
                        return (
                            <Grid item xs style={{display: "contents"}}>
                                <img src={"../b4b7289143f6-able_brand_signature_greens_rgb.png"} alt="able logo" className={classes.image} style={{width: "30%", paddingTop: 20, paddingBottom: 20}}/>
                            </Grid>
                        )
                    } else if (shop?.name.includes("Bolou")) {
                        return (
                            <Grid item xs style={{display: "contents"}}>
                                <img src={"../bolou_logo.png"} alt="able logo" className={classes.image} style={{width: "50%", paddingTop: 0, paddingBottom: 0}}/>
                            </Grid>
                        )
                    } else if (shop?.name.includes("Nykredit")) {
                        return (
                            <Grid item xs style={{display: "contents"}}>
                                <img src={"../png_logo_Nykredit_Horisont.png"} alt="nykredit logo" className={classes.image} style={{width: "50%", paddingTop: 0, paddingBottom: 0}}/>
                            </Grid>
                        )
                    } else if (shop?.name.includes("Ledernes")) {
                        return (
                            <Grid item xs style={{display: "contents"}}>
                                <img src={"../lederne.png"} alt="ledernes logo" className={classes.image} style={{width: "50%", paddingTop: 0, paddingBottom: 0}}/>
                            </Grid>
                        )
                    } else if (shop?.name.includes("Nyhavn")) {
                        return (
                            <Grid item xs style={{display: "contents"}}>
                                <img src={"../download-1.png"} alt="nyhavn logo" className={classes.image} style={{width: "50%", paddingTop: 0, paddingBottom: 0}}/>
                            </Grid>
                        )
                    } else if (shop?.name.includes("Snevagten")) {
                        return (
                            <Grid item xs style={{display: "contents"}}>
                                <img src={"../Snevagten.png"} alt="Snevagten logo" className={classes.image} style={{width: "50%", paddingTop: 0, paddingBottom: 0}}/>
                            </Grid>
                        )
                    } else if (shop?.name.includes("Bilhandel")) {
                        return (
                            <Grid item xs style={{display: "contents"}}>
                                <img src={"../bilhandel_logo.png"} alt="Bilhandel logo" className={classes.image} style={{width: "50%", paddingTop: 20, paddingBottom: 20}}/>
                            </Grid>
                        )
                    } else if (shop?.name.includes("Diialogg")) {
                        return (
                            <Grid item xs style={{display: "contents"}}>
                                <img src={"../Asset_14x-8.png"} alt="Diialogg logo" className={classes.image} style={{width: "50%", paddingTop: 20, paddingBottom: 20}}/>
                            </Grid>
                        )
                    } else if (shop?.name.includes("Autotek")) {
                        return (
                            <Grid item xs style={{display: "contents"}}>
                                <img src={"../autotek_dk_logo_gjennomsiktigh.png"} alt="Autotek logo" className={classes.image} style={{width: "50%", paddingTop: 20, paddingBottom: 20}}/>
                            </Grid>
                        )
                    } else if (shop?.name.includes("NB FILM")) {
                        return (
                            <Grid item xs style={{display: "contents"}}>
                                <img src={"../white_logo_color1_background.png"} alt="nbfilm logo" className={classes.image} style={{width: "60%", paddingTop: 0, paddingBottom: 0}}/>
                            </Grid>
                        )
                    } else if (shop?.name.includes("Poul Stig")) {
                        return (
                            <Grid item xs style={{display: "contents"}}>
                                <img src={"../poulstig_briller_logo.png"} alt="poul stig logo" className={classes.image} style={{width: "50%", paddingTop: 20, paddingBottom: 20}}/>
                            </Grid>
                        )
                    } else if (shop?.name.includes("Jobboozt")) {
                        return (
                            <Grid item xs style={{display: "contents"}}>
                                <img src={"../Jobboozt_Rekruttering_Headhunting.png"} alt="Jobboozt logo" className={classes.image} style={{width: "50%", paddingTop: 0, paddingBottom: 0}}/>
                            </Grid>
                        )
                    } else if (shop?.name.includes("Urban Coffee")) {
                        return (
                            <Grid item xs style={{display: "contents"}}>
                                <img src={"../UrbanCoffee_Skyline.png"} alt="Urban Coffee logo" className={classes.image} style={{width: "50%", paddingTop: 0, paddingBottom: 0}}/>
                            </Grid>
                        )
                    } else if (shop?.name.includes("Mæglerakademiet")) {
                        return (
                            <Grid item xs style={{display: "contents"}}>
                                <img src={"../Mæglerakademiet_logo.png"} alt="Mæglerakademiet logo" className={classes.image} style={{width: "50%", paddingTop: 20, paddingBottom: 20}}/>
                            </Grid>
                        )
                    } else if (shop?.name.includes("ejendrøm")) {
                        return (
                            <Grid item xs style={{display: "contents"}}>
                                <img src={"../Logo_Grøn_baggrund.png"} alt="ejendrøm logo" className={classes.image} style={{width: "50%", paddingTop: 0, paddingBottom: 0}}/>
                            </Grid>
                        )
                    } else if (shop?.name.toLowerCase().includes("kco")) {
                        return (
                            <Grid item xs style={{display: "contents"}}>
                                <img src={"../KCO_Bolig_Logo_Sort___Kopi.png"} alt="kco logo" className={classes.image} style={{width: "50%", paddingTop: 0, paddingBottom: 0}}/>
                            </Grid>
                        )
                    } else if (shop?.name.toLowerCase().includes("ebeltoft")) {
                        return (
                            <Grid item xs style={{display: "contents"}}>
                                <img src={"../EMM.Instagram.png"} alt="emm logo" className={classes.image} style={{width: "50%", paddingTop: 0, paddingBottom: 0}}/>
                            </Grid>
                        )
                    } else if (shop?.name.toLowerCase().includes("boligventilation")) {
                        return (
                            <Grid item xs style={{display: "contents"}}>
                                <img src={"../dinboligventilation.png"} alt="din boligventilation logo" className={classes.image} style={{width: "50%", paddingTop: 0, paddingBottom: 0}}/>
                            </Grid>
                        )
                    } else {
                        return (
                            <>
                            <Grid item xs={3} md={2} className={classes.imageContainer}>
                                <Avatar alt={shop?.name} src={shop?.logoUrl} variant="square" className={classes.image} />
                            </Grid>

                            <Grid item xs={9} md={10} className={classes.nameContainer}>
                                <Box p={1}>
                                    <Typography variant="subtitle2" component="h2">{shop?.name}</Typography>
                                    <Typography style={{color: shop?.subtitleTextColor}} variant="caption" display="block">{shop?.subname}</Typography>
                                </Box>
                            </Grid>
                            </>
                        )
                    } 
                })()
            }
        </Grid>
    )
}
export default HeaderArea;